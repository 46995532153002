<template>
  <app-page ref="page" :toolbar="toolbar" :list="list">
    <template #actions="{ row }">
      <el-button
        :loading="loading"
        icon="el-icon-bell"
        type="primary"
        :disabled="row.status != 0"
        @click="onNotice(row)"
      >
        通知
      </el-button>
      <el-button
        :loading="loading"
        icon="el-icon-check"
        type="success"
        :disabled="row.status != 1"
        @click="onConfirm(row)"
      >
        确认
      </el-button>
    </template>
  </app-page>
</template>

<script>
import request from '@/utils/request';

export default {
  name: 'ListPane',
  props: { prefix: String },
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '80px',
        igrone: ['date'],
        model: {
          name: undefined,
          date: undefined,
          status: undefined
        },
        fields: [
          { label: '名称', prop: 'name', placeholder: '请输入要搜索的名称' },
          {
            label: '预约日期',
            prop: 'date',
            placeholder: '请选择要搜索的预约日期',
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd'
          },
          {
            label: '状态',
            prop: 'status',
            placeholder: '请输入要搜索的状态',
            type: 'select',
            options: [
              {
                label: '全部',
                value: undefined
              },
              {
                label: '未使用',
                value: 0
              },
              {
                label: '已使用',
                value: 1
              },
              {
                label: '已确认',
                value: 2
              },
              {
                label: '已取消',
                value: 3
              }
            ]
          }
        ]
      },
      list: {
        customAction: {
          width: '220px'
        },
        method: 'post',
        url: `${this.prefix}/appointment`,
        fields: [
          { label: '姓名', prop: 'appointmentUserName', width: 120 },
          { label: '电话', prop: 'appointmentPhone', width: 160 },
          {
            label: '预约日期',
            prop: 'appointmentDate',
            width: 150
          },
          {
            label: '预约时间',
            prop: 'appointmentTime',
            width: 150,
            formatter(row) {
              return `${row.startTime}~${row.endTime}`;
            }
          },
          {
            label: '预约项目',
            prop: 'medicalNewProjectName',
            minWidth: 120
          },
          {
            label: '是否会员价',
            prop: 'isVip',
            width: 100,
            align: 'center',
            formatter(row) {
              return row.isVip != '0' ? '是' : '否';
            }
          },
          {
            label: '金额',
            prop: 'amount',
            width: 100,
            align: 'right',
            color: 'danger',
            formatter(row) {
              return `￥${row.amount}`;
            }
          },
          {
            label: '状态',
            prop: 'status',
            width: 100,
            align: 'right',
            colors: ['primary', 'warning', 'success', 'info'],
            formatter(row) {
              const values = ['未使用', '已使用', '已确认', '已取消'];
              return values[row.status];
            }
          }
        ]
      },
      loading: false
    };
  },
  methods: {
    async onNotice(e) {
      const { $refs, $confirm } = this;
      try {
        await $confirm('是否发送通知', '提示');
        this.loading = true;
        await request.post(`${this.prefix}/appointment/sendMessage`, {
          id: e.id
        });
        await $refs.page.getItems();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async onConfirm(e) {
      const { $refs, $confirm } = this;
      try {
        await $confirm('是否完成服务并确认', '提示');
        this.loading = true;
        await request.post(`${this.prefix}/appointment/confirm`, {
          id: e.id,
          operateUserId: sessionStorage.getItem('userId')
        });
        await $refs.page.getItems();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
