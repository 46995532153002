<template>
  <app-tabs v-model="active">
    <el-tab-pane label="针灸、拔罐等预约" lazy>
      <list-pane prefix="medicalNew" />
    </el-tab-pane>
    <el-tab-pane label="推拿预约" lazy>
      <list-pane prefix="medicalNewTwo" />
    </el-tab-pane>
  </app-tabs>
</template>

<script>
import listPane from './listPane';

export default {
  name: 'Setting',
  components: { listPane },
  data() {
    return {
      active: '0'
    };
  }
};
</script>
